import React from 'react';
import { css } from '@emotion/react';
import { Col, Row, Image } from 'antd';
import SectionDetail from '../../../../components/SectionDetail';
import uxUiDesignImg from '../../../../assets/ux_ui.png';
import PDF from '../../../../components/PDF';
import Text from '../../../../components/Text';

import nanoDegree from '../../assets/ux_ui_design/nano_degree.png';

import phoneViews from '../../assets/ux_ui_design/3_views.png';

import cozy from '../../assets/ux_ui_design/summary_cozy.pdf';
import midTerm from '../../assets/ux_ui_design/midterm_project.pdf';
import researchPhase from '../../assets/ux_ui_design/research_phase.pdf';
import finalProject from '../../assets/ux_ui_design/final_project_cozy.pdf';
import { defaultGutter } from '../../../../lib/common/styles';

const description: string[] = [
  "Design is no longer an artistic practice and creativity is not just an artist's emotional expression.",
  'Designers are the solution to solving problems that enable people, businesses, and technology to impact how the world works. Through personal experience, experiments, and research, I’ve evolved from being an artist into a scientific designer. Designers who are systematic, methodic, and process driven innovate more strategically.',
  'My journey as a multibrached designer made me realize at one point the importance of user centered design in every product on earth.',
  'From there I started diving more in the UX/UI field, and I found myself more and more curious about understanding people’s choices and what drives their behaviors to interact with certain environments.',
];

const tasksList: string[] = [
  'Scoping and defining problems to solve',
  'Conduting User reseach',
  'Using affinity mapping to analyze user data',
  'Using themes & opportunities to synthesize data',
  'Using feature ideation to populate product features',
  'Using value-complexity quadrant to select product features to move forward with',
  'Making sketches & wireframes',
  'Validating the lo-fi prototype using usability testing',
  'Designing hi-fi prototype using Figma and getting ready for development using Zeplin',
  'Conducting the last usability testing using Lookback',
];

const UxUiDesignPage: React.FC = () => {
  return (
    <SectionDetail title="As a UX/UI designer" imgSrc={uxUiDesignImg}>
      <>
        <div>
          {description.map((d, i) => (
            <p key={i}>{d}</p>
          ))}
        </div>
        <Text type="sub-title-1">Academic experience</Text>
        <Row gutter={[defaultGutter, defaultGutter]}>
          <Col sm={0} md={3} />
          <Col sm={24} md={18}>
            <Image src={nanoDegree} />
          </Col>
          <Col sm={0} md={3} />
        </Row>
        <Text type="sub-title-1">Main projects</Text>
        <Row gutter={[defaultGutter, defaultGutter]}>
          <Col sm={0} md={2} />
          <Col sm={24} md={20}>
            <Image src={phoneViews} />
          </Col>
          <Col sm={0} md={2} />
        </Row>
        <Row gutter={[defaultGutter, defaultGutter]}>
          <ul>
            {tasksList.map((task, i) => (
              <li key={i}>{task}</li>
            ))}
          </ul>
        </Row>
        <Row gutter={[defaultGutter, defaultGutter]}>
          <Col sm={0} md={2} />
          <Col sm={24} md={20}>
            <PDF src={researchPhase} />
          </Col>
          <Col sm={0} md={2} />
        </Row>
        <Row gutter={[defaultGutter, defaultGutter]}>
          <Col sm={0} md={2} />
          <Col sm={24} md={20}>
            <PDF src={cozy} scale={0.5} />
          </Col>
          <Col sm={0} md={2} />
        </Row>
        <Row gutter={[defaultGutter, defaultGutter]}>
          <Col sm={0} md={2} />
          <Col sm={24} md={20}>
            <PDF
              src={midTerm}
              scale={0.7}
              pageCSS={css`
                height: 800px;
              `}
            />
          </Col>
          <Col sm={0} md={2} />
        </Row>
        <Row gutter={[defaultGutter, defaultGutter]}>
          <Col sm={0} md={2} />
          <Col sm={24} md={20}>
            <PDF
              src={finalProject}
              scale={0.9}
              pageCSS={css`
                height: 800px;
              `}
            />
          </Col>
          <Col sm={0} md={2} />
        </Row>
      </>
    </SectionDetail>
  );
};

export default UxUiDesignPage;
