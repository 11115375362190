import { css, ClassNames } from '@emotion/react';
import styled from '@emotion/styled';
import {
  BREAKING_POINT,
  defaultGutter,
  fontSourceSansProBold,
  getRandomColor,
  getRandomNumber,
  RANDOM_COLORS,
} from '../../../../lib/common/styles';

export const mobileViewContainer = css`
  @media only screen and (max-width: ${BREAKING_POINT}px) {
    flex-direction: column-reverse;
  }
`;

export const Year = styled.div`
  ${fontSourceSansProBold};
  font-size: 1.5em;
`;

export const TitleWrapper = styled.div`
  font-size: 2em;
  text-align: center;
  @media only screen and (max-width: ${BREAKING_POINT}px) {
    font-size: 1.5em;
  }
`;

export const revertedStyle = css`
  flex-direction: row-reverse;
`;

export const marginedRow = css`
  margin: ${5 * defaultGutter}px 0;
`;
