import React from 'react';
import { LinkedinOutlined, MailOutlined } from '@ant-design/icons';
import { ContactItem, iconStyle } from './styles';
import Text from '../../../../components/Text';

function ContactCard() {
  return (
    <>
      <Text type="spread-title">Contact</Text>
      <ContactItem>
        <MailOutlined css={iconStyle} />
        <Text>ansembenromdhane@gmail.com</Text>
      </ContactItem>
    </>
  );
}

export default ContactCard;
