import { SerializedStyles } from '@emotion/react';
import { Gutter } from 'antd/lib/grid/row';

import monster1 from '../../assets/fine_arts/originals/monster_1.jpg';
import monster2 from '../../assets/fine_arts/originals/monster_2.jpg';
import monster1C from '../../assets/fine_arts/compressed/monster_1.jpg';
import monster2C from '../../assets/fine_arts/compressed/monster_2.jpg';

import oldLady1 from '../../assets/fine_arts/originals/old_lady_1.jpg';
import oldLady2 from '../../assets/fine_arts/originals/old_lady_2.jpg';
import oldLady1C from '../../assets/fine_arts/compressed/old_lady_1.jpg';
import oldLady2C from '../../assets/fine_arts/compressed/old_lady_2.jpg';

import woman1 from '../../assets/fine_arts/originals/woman_1.jpg';
import woman2 from '../../assets/fine_arts/originals/woman_2.jpg';
import woman1C from '../../assets/fine_arts/compressed/woman_1.jpg';
import woman2C from '../../assets/fine_arts/compressed/woman_2.jpg';

import grass1 from '../../assets/fine_arts/originals/grass_1.jpg';
import grass2 from '../../assets/fine_arts/originals/grass_2.jpg';
import grass1C from '../../assets/fine_arts/compressed/grass_1.jpg';
import grass2C from '../../assets/fine_arts/compressed/grass_2.jpg';

import miniPics from '../../assets/fine_arts/originals/mini_pics.jpg';
import miniPicsC from '../../assets/fine_arts/compressed/mini_pics.jpg';

import water1 from '../../assets/fine_arts/originals/water_1.jpg';
import water2 from '../../assets/fine_arts/originals/water_2.jpg';
import water3 from '../../assets/fine_arts/originals/water_3.jpg';
import water4 from '../../assets/fine_arts/originals/water_4.jpg';
import water1C from '../../assets/fine_arts/compressed/water_1.jpg';
import water2C from '../../assets/fine_arts/compressed/water_2.jpg';
import water3C from '../../assets/fine_arts/compressed/water_3.jpg';
import water4C from '../../assets/fine_arts/compressed/water_4.jpg';

import train1 from '../../assets/fine_arts/originals/train_1.jpg';
import train2 from '../../assets/fine_arts/originals/train_2.jpg';
import train1C from '../../assets/fine_arts/compressed/train_1.jpg';
import train2C from '../../assets/fine_arts/compressed/train_2.jpg';

import cartoon1 from '../../assets/fine_arts/originals/cartoon_1.jpg';
import cartoon2 from '../../assets/fine_arts/originals/cartoon_2.jpg';
import cartoon1C from '../../assets/fine_arts/compressed/cartoon_1.jpg';
import cartoon2C from '../../assets/fine_arts/compressed/cartoon_2.jpg';

import garden1 from '../../assets/fine_arts/originals/garden_1.jpg';
import garden2 from '../../assets/fine_arts/originals/garden_2.jpg';
import garden1C from '../../assets/fine_arts/compressed/garden_1.jpg';
import garden2C from '../../assets/fine_arts/compressed/garden_2.jpg';

import frida1 from '../../assets/fine_arts/originals/frida_1.jpg';
import fridaColors from '../../assets/fine_arts/originals/frida_colors.jpg';
import frida1C from '../../assets/fine_arts/compressed/frida_1.jpg';
import fridaColorsC from '../../assets/fine_arts/compressed/frida_colors.jpg';

import house from '../../assets/fine_arts/originals/house.jpg';
import houseColors from '../../assets/fine_arts/originals/house_colors.jpg';
import houseC from '../../assets/fine_arts/compressed/house.jpg';
import houseColorsC from '../../assets/fine_arts/compressed/house_colors.jpg';

import cowboy1 from '../../assets/fine_arts/originals/cow_boy.jpg';
import cowboy2 from '../../assets/fine_arts/originals/cowboy.jpg';
import cowboy1C from '../../assets/fine_arts/compressed/cow_boy.jpg';
import cowboy2C from '../../assets/fine_arts/compressed/cowboy.jpg';

import womanLines1 from '../../assets/fine_arts/originals/woman_lines_1.jpg';
import womanLines2 from '../../assets/fine_arts/originals/woman_lines_2.jpg';
import womanLines1C from '../../assets/fine_arts/compressed/woman_lines_1.jpg';
import womanLines2C from '../../assets/fine_arts/compressed/woman_lines_2.jpg';

import yellow1 from '../../assets/fine_arts/originals/yellow_1.jpg';
import yellow2 from '../../assets/fine_arts/originals/yellow_2.jpg';
import yellow1C from '../../assets/fine_arts/compressed/yellow_1.jpg';
import yellow2C from '../../assets/fine_arts/compressed/yellow_2.jpg';

import womanCreepy1 from '../../assets/fine_arts/originals/woman_creepy_1.jpg';
import womanCreepy2 from '../../assets/fine_arts/originals/woman_creepy_2.jpg';
import womanCreepy1C from '../../assets/fine_arts/compressed/woman_creepy_1.jpg';
import womanCreepy2C from '../../assets/fine_arts/compressed/woman_creepy_2.jpg';

import youngLady1 from '../../assets/fine_arts/originals/yong_lady_1.jpg';
import youngLady2 from '../../assets/fine_arts/originals/yong_lady_2.jpg';
import youngLady1C from '../../assets/fine_arts/compressed/yong_lady_1.jpg';
import youngLady2C from '../../assets/fine_arts/compressed/yong_lady_2.jpg';

import hair1 from '../../assets/fine_arts/originals/hair_1.jpg';
import hair2 from '../../assets/fine_arts/originals/hair_2.jpg';
import hair1C from '../../assets/fine_arts/compressed/hair_1.jpg';
import hair2C from '../../assets/fine_arts/compressed/hair_2.jpg';

import elephant from '../../assets/fine_arts/originals/elephant.jpg';
import elephantC from '../../assets/fine_arts/compressed/elephant.jpg';

import saint from '../../assets/fine_arts/originals/saint.jpg';
import saintC from '../../assets/fine_arts/compressed/saint.jpg';

import { spaceBetweenColumn } from '../../styles';

interface IImage {
  org: string;
  compressed: string;
}

interface IColumn {
  imgs: IImage[];
  size: number;
  css?: SerializedStyles;
}

export interface IOneLine {
  gutter?: number | [Gutter, Gutter];
  columns: IColumn[];
}

export const data: IOneLine[] = [
  {
    columns: [
      { imgs: [], size: 3 },
      { imgs: [{ org: saint, compressed: saintC }], size: 18 },
      { imgs: [], size: 3 },
    ],
  },
  {
    columns: [
      { imgs: [{ org: monster1, compressed: monster1C }], size: 12 },
      { imgs: [{ org: monster2, compressed: monster2C }], size: 11 },
    ],
  },
  {
    columns: [
      { imgs: [{ org: woman2, compressed: woman2C }], size: 12 },
      { imgs: [{ org: woman1, compressed: woman1C }], size: 12 },
    ],
  },
  {
    columns: [
      { imgs: [{ org: oldLady1, compressed: oldLady1C }], size: 12 },
      { imgs: [{ org: oldLady2, compressed: oldLady2C }], size: 11 },
    ],
  },
  {
    columns: [
      { imgs: [{ org: miniPics, compressed: miniPicsC }], size: 12 },
      {
        imgs: [
          { org: water1, compressed: water1C },
          { org: water4, compressed: water4C },
        ],
        size: 6,
        css: spaceBetweenColumn,
      },
      {
        imgs: [
          { org: water2, compressed: water2C },
          { org: water3, compressed: water3C },
        ],
        size: 6,
        css: spaceBetweenColumn,
      },
    ],
  },
  {
    columns: [
      { imgs: [{ org: train1, compressed: train1C }], size: 12 },
      { imgs: [{ org: train2, compressed: train2C }], size: 12 },
    ],
  },
  {
    columns: [
      { imgs: [{ org: garden2, compressed: garden2C }], size: 12 },
      { imgs: [{ org: garden1, compressed: garden1C }], size: 11 },
    ],
  },
  {
    columns: [
      { imgs: [{ org: cartoon1, compressed: cartoon1C }], size: 12 },
      { imgs: [{ org: cartoon2, compressed: cartoon2C }], size: 12 },
    ],
  },
  {
    columns: [
      { imgs: [{ org: grass1, compressed: grass1C }], size: 9 },
      { imgs: [{ org: grass2, compressed: grass2C }], size: 15 },
    ],
  },
  {
    columns: [
      { imgs: [{ org: frida1, compressed: frida1C }], size: 9 },
      { imgs: [{ org: fridaColors, compressed: fridaColorsC }], size: 15 },
    ],
  },
  {
    columns: [
      { imgs: [{ org: hair1, compressed: hair1C }], size: 9 },
      { imgs: [{ org: hair2, compressed: hair2C }], size: 15 },
    ],
  },
  {
    columns: [
      { imgs: [{ org: house, compressed: houseC }], size: 9 },
      { imgs: [{ org: houseColors, compressed: houseColorsC }], size: 15 },
    ],
  },
  {
    columns: [
      { imgs: [{ org: cowboy2, compressed: cowboy2C }], size: 12 },
      { imgs: [{ org: cowboy1, compressed: cowboy1C }], size: 11 },
    ],
  },
  {
    columns: [
      { imgs: [{ org: womanLines1, compressed: womanLines1C }], size: 12 },
      { imgs: [{ org: womanLines2, compressed: womanLines2C }], size: 12 },
    ],
  },
  {
    columns: [
      { imgs: [{ org: yellow1, compressed: yellow1C }], size: 12 },
      { imgs: [{ org: yellow2, compressed: yellow2C }], size: 12 },
    ],
  },
  {
    columns: [
      { imgs: [{ org: womanCreepy1, compressed: womanCreepy1C }], size: 12 },
      { imgs: [{ org: womanCreepy2, compressed: womanCreepy2C }], size: 12 },
    ],
  },
  {
    columns: [
      { imgs: [{ org: youngLady1, compressed: youngLady1C }], size: 15 },
      { imgs: [{ org: youngLady2, compressed: youngLady2C }], size: 9 },
    ],
  },
  {
    columns: [{ imgs: [{ org: elephant, compressed: elephantC }], size: 12 }],
  },
];
