import { Col, Row, Image } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { SerializedStyles } from '@emotion/react';
import { spaceBetweenColumn } from '../../styles';

import { defaultGutter } from '../../../../lib/common/styles';
import SectionDetail from '../../../../components/SectionDetail';
import { data } from './data';

function FineArts() {
  return (
    <SectionDetail title="My Sketchbook">
      <br />
      <br />
      {data.map((row, i) => (
        <Row
          key={i}
          gutter={row.gutter ? row.gutter : [defaultGutter, 5 * defaultGutter]}
        >
          {row.columns.map((column, j) => (
            <Col
              key={j}
              sm={24}
              md={column.size}
              css={column.css ? column.css : undefined}
            >
              {column.imgs.map((img, l) => (
                <Image
                  key={l}
                  src={img.compressed}
                  preview={{ src: img.org }}
                />
              ))}
            </Col>
          ))}
        </Row>
      ))}
    </SectionDetail>
  );
}

export default FineArts;
