import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { COLORS, defaultGutter, GUTTER } from '../../../../lib/common/styles';

export const ContentWrapper = styled.div`
  flex: 1 0 auto;
`;

export const mainContentStyle = css`
  padding: ${defaultGutter}px;
  margin: ${GUTTER.large}px 0;
  min-height: 73vh;
`;
