import { css } from '@emotion/react';
import { Col } from 'antd';
import React, { RefObject, useEffect, useState } from 'react';
import { useMappedWidthScale } from '../../../../../lib/common/listeners';
import { defaultGutter } from '../../../../../lib/common/styles';
import ArrowLink from '../../ArrowLink';
import { TitleWrapper } from '../styles';

interface IProps {
  height: string;
  width: string;
  path: string;
  length: string;
  reverted: boolean;
  horizontalGradient: boolean;
  visibilityRef: RefObject<HTMLDivElement>;
}
const MiddleSection: React.FC<IProps> = ({
  width,
  height,
  horizontalGradient,
  length,
  path,
  reverted,
  visibilityRef,
}: IProps) => {
  const scale = useMappedWidthScale(900, 1770, 1.1, 1.5);
  return (
    <Col
      xs={0}
      sm={0}
      md={2}
      css={css`
        margin-top: ${2 * defaultGutter}px;
      `}
    >
      <ArrowLink
        height={height}
        width={width}
        scale={scale}
        path={path}
        length={length}
        invertedAnimation={reverted}
        horizontalGradient
        visibilityRef={visibilityRef}
      />
    </Col>
  );
};

export default React.memo(MiddleSection);
