import { Row, Col, Image } from 'antd';
import SectionDetail from '../../components/SectionDetail';
import explore from '../../assets/explore.png';
import { defaultGutter } from '../../lib/common/styles';

import gvCertC from './assets/compressed/gv_cert.jpg';
import geCertC from './assets/compressed/ge_cert.jpg';
import gvCert from './assets/originals/gv_cert.jpg';
import geCert from './assets/originals/ge_cert.jpg';

import Text from '../../components/Text';

const gvDescription = `
This project was an act to encourage kids to learn about other
cultures tackling the sustainable development goal "Quality
Education". I believe that children are the future of our societies,
  teaching them the necessity of solidarity, community and togetherness
is the kew to sociability. My aim is to influence young people and
students with the principals of self care, self confidence, believing
in ones abilities, being ambitious, and daring to achieve their dreams
which are the main fundamentals to prove themselves as individuals and
to leave a mark on the society. I have had the best learning
experience and I could feel comfortable while getting out of the
comfort zone. More than that, I learned to be responsible and
independent in a totally different country.
`;

const geDescription = `
The best value of this program is the opportunity to meet amazing
people that helped me grow and see things from different prespectives.
I became more humble, more mature, and confident about my professional
skilss. I had a chance to do convey a lot of research in order to give
recommendations to know what can be implemented and will help the
startup strategically. I have also gained a lot of practical
experience, getting involved in the local community, meeting the
greates people with like-minded souls and trying to embrace the
unexpected.
`;

function ExploreMyselfPage() {
  return (
    <SectionDetail title="Exploring Myself" imgSrc={explore}>
      <Row>
        <Text type="sub-title-1">Voluntary Experiences</Text>
      </Row>
      <Row gutter={[defaultGutter, defaultGutter]}>
        <Col sm={0} md={3} />
        <Col sm={24} md={18}>
          <Image src={gvCertC} preview={{ src: gvCert }} />
        </Col>
        <Col sm={0} md={3} />
      </Row>
      <Row>
        <Text type="sub-title-1">Global volunteer - Czech Republic, 2019</Text>
      </Row>
      <Row>
        <p>{gvDescription}</p>
      </Row>
      <Row gutter={[defaultGutter, defaultGutter]}>
        <Col sm={0} md={3} />
        <Col sm={24} md={18}>
          <Image src={geCertC} preview={{ src: geCert }} />
        </Col>
        <Col sm={0} md={3} />
      </Row>
      <Row>
        <Text type="sub-title-1">Global Entrepreneur - Romania, 2020</Text>
      </Row>
      <Row>
        <p>{geDescription}</p>
      </Row>
    </SectionDetail>
  );
}

export default ExploreMyselfPage;
