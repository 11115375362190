import React from 'react';
import { Col, Row, Image } from 'antd';
import { css } from '@emotion/react';
import SectionDetail from '../../../../components/SectionDetail';
import productDesignImg from '../../../../assets/product_design.png';

import wallet1 from '../../assets/product_design/originals/wallet_1.png';
import wallet2 from '../../assets/product_design/originals/wallet_2.png';
import wallet1C from '../../assets/product_design/compressed/wallet_1.png';
import wallet2C from '../../assets/product_design/compressed/wallet_2.png';

import bp1 from '../../assets/product_design/originals/bp_1.jpg';
import bp2 from '../../assets/product_design/originals/bp_2.jpg';
import bp3 from '../../assets/product_design/originals/bp_3.jpg';
import bp4 from '../../assets/product_design/originals/bp_4.png';
import bp1C from '../../assets/product_design/compressed/bp_1.jpg';
import bp2C from '../../assets/product_design/compressed/bp_2.jpg';
import bp3C from '../../assets/product_design/compressed/bp_3.jpg';
import bp4C from '../../assets/product_design/compressed/bp_4.png';

import laptopCase1 from '../../assets/product_design/originals/laptop_case_1.png';
import laptopCase2 from '../../assets/product_design/originals/laptop_case_2.png';
import laptopCase1C from '../../assets/product_design/compressed/laptop_case_1.png';
import laptopCase2C from '../../assets/product_design/compressed/laptop_case_2.png';

import penBag from '../../assets/product_design/originals/pen_bag.jpg';
import penBagC from '../../assets/product_design/compressed/pen_bag.jpg';

import { spaceBetweenColumn } from '../../styles';
import { defaultGutter } from '../../../../lib/common/styles';

const mainProjectDescription1 = `Designed leather goods at `;
const mainProjectDescription2 = ` to meet the customer's specific needs.`;

const mainProjectDescription = (link: React.ReactNode) => (
  <>
    <>{mainProjectDescription1}</>
    {link}
    <>{mainProjectDescription2}</>
  </>
);

function ProductDesignPage() {
  return (
    <SectionDetail title="As a product designer" imgSrc={productDesignImg}>
      <Row>
        <h2>Professional Experience</h2>
      </Row>
      <Row>
        <ul>
          <li>
            Lead wide studies on competitors and used marketing strategies.
          </li>
          <li>Wireframing and sketching unsing Photoshop.</li>
          <li>
            Setup and designed user based product using Illustrator, Photoshop,
            and SolidWorks.
          </li>
          <li>Edited and optimized lookbooks for social media platforms.</li>
        </ul>
      </Row>
      <Row>
        <h2>Main Projects</h2>
      </Row>
      <div>
        {mainProjectDescription(
          <b
            css={css`
              text-decoration: underline;
            `}
          >
            <a href="https://tefors.com" target="_blank" rel="noreferrer">
              Tefors
            </a>
          </b>,
        )}
      </div>
      <br />
      <Row gutter={[defaultGutter, defaultGutter]}>
        <Col sm={24} md={12}>
          <Image src={wallet2C} preview={{ src: wallet2 }} />
        </Col>
        <Col sm={24} md={12}>
          <Image src={wallet1C} preview={{ src: wallet1 }} />
        </Col>
      </Row>
      <Row gutter={[0, defaultGutter]}>
        <Image src={bp4C} preview={{ src: bp4 }} />
      </Row>
      <Row gutter={[0, defaultGutter]}>
        <Col sm={0} md={2} />
        <Col sm={24} md={20}>
          <Image src={penBagC} preview={{ src: penBag }} />
        </Col>
        <Col sm={0} md={2} />
      </Row>
      <Row gutter={[defaultGutter, defaultGutter]}>
        <Col sm={24} md={8}>
          <Image src={bp1C} preview={{ src: bp1 }} />
        </Col>
        <Col sm={24} md={8}>
          <Image src={bp2C} preview={{ src: bp2 }} />
        </Col>
        <Col sm={24} md={8}>
          <Image src={bp3C} preview={{ src: bp3 }} />
        </Col>
      </Row>
      <Row gutter={[defaultGutter, defaultGutter]}>
        <Col sm={24} md={12} css={spaceBetweenColumn}>
          <Image src={laptopCase1C} preview={{ src: laptopCase1 }} />
        </Col>
        <Col sm={24} md={12}>
          <Image src={laptopCase2C} preview={{ src: laptopCase2 }} />
        </Col>
      </Row>
    </SectionDetail>
  );
}

export default ProductDesignPage;
