import React, { useMemo } from 'react';
import { getRandomColor } from '../../../../lib/common/styles';

interface IProps {
  horizontalGradient: boolean;
  isVisible: boolean;
  gradientId: string;
}

const LinearGradient: React.FC<IProps> = ({
  horizontalGradient,
  isVisible,
  gradientId,
}: IProps) => {
  const colors = useMemo(() => Array.from(Array(5), getRandomColor), [
    isVisible,
  ]);

  return (
    <defs>
      <linearGradient
        id={gradientId}
        x1="0%"
        y1="0%"
        x2={horizontalGradient ? '100%' : '0%'}
        y2={horizontalGradient ? '0%' : '100%'}
      >
        <stop offset="0%" stopColor={colors[0]} />
        <stop offset="25%" stopColor={colors[1]} />
        <stop offset="50%" stopColor={colors[2]} />
        <stop offset="75%" stopColor={colors[3]} />
        <stop offset="100%" stopColor={colors[4]} />
      </linearGradient>
    </defs>
  );
};

export default React.memo(LinearGradient);
