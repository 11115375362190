import React from 'react';
import { Col, Row, Image } from 'antd';
import { css } from '@emotion/react';
import SectionDetail from '../../../../components/SectionDetail';
import visualDesignImg from '../../../../assets/visual_design.png';
import { defaultGutter } from '../../../../lib/common/styles';

import cataloguePdf from '../../assets/visual_design/originals/catalogue_pdf.jpg';
import cataloguePdfC from '../../assets/visual_design/compressed/catalogue_pdf.jpg';

import careKit from '../../assets/visual_design/originals/carekit.jpg';
import careKitC from '../../assets/visual_design/compressed/carekit.jpg';

import careCatalogue from '../../assets/visual_design/originals/care_catalogue.jpg';
import careCatalogueC from '../../assets/visual_design/compressed/care_catalogue.jpg';

import moveMountains from '../../assets/visual_design/originals/move_mountains.png';
import moveMountainsC from '../../assets/visual_design/compressed/move_mountains.jpg';

import wantToRead from '../../assets/visual_design/originals/want_to_read.jpg';
import wantToReadC from '../../assets/visual_design/compressed/want_to_read.jpg';

import oneDay from '../../assets/visual_design/originals/one_day.jpg';
import oneDayC from '../../assets/visual_design/compressed/one_day.jpg';

import okami from '../../assets/visual_design/originals/okami.jpg';
import okamiC from '../../assets/visual_design/compressed/okami.jpg';

import totoro from '../../assets/visual_design/originals/totoro.png';
import totoroC from '../../assets/visual_design/compressed/totoro.png';

const columnStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

function VisualDesignPage() {
  return (
    <SectionDetail title="As a visual designer" imgSrc={visualDesignImg}>
      <>
        <Row gutter={[defaultGutter, defaultGutter]}>
          <Col sm={24} md={12} css={columnStyle}>
            <Image src={careKitC} preview={{ src: careKit }} />
            <Image src={careCatalogueC} preview={{ src: careCatalogue }} />
          </Col>
          <Col sm={24} md={12}>
            <Image src={cataloguePdfC} preview={{ src: cataloguePdf }} />
          </Col>
        </Row>
        <Row gutter={[0, defaultGutter]}>
          <Image src={moveMountainsC} preview={{ src: moveMountains }} />
        </Row>
        <Row gutter={[defaultGutter, defaultGutter]}>
          <Col sm={24} md={12}>
            <Image src={wantToReadC} preview={{ src: wantToRead }} />
          </Col>
          <Col sm={24} md={12}>
            <Image src={oneDayC} preview={{ src: oneDay }} />
          </Col>
        </Row>
        <Row gutter={[0, defaultGutter]}>
          <Image src={okamiC} preview={{ src: okami }} />
        </Row>
        <Row gutter={[0, defaultGutter]}>
          <Col sm={0} md={6} />
          <Col sm={24} md={12}>
            <Image src={totoroC} preview={{ src: totoro }} />
          </Col>
          <Col sm={0} md={6} />
        </Row>
      </>
    </SectionDetail>
  );
}

export default VisualDesignPage;
