import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import React, { useState } from 'react';
import { centeredFlex } from '../../../lib/common/styles';
import { mobileMenuStyle } from '../style';

interface IProps {
  children: React.ReactNode;
}

const MobileMenu: React.FC<IProps> = ({ children }: IProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <>
      <div css={centeredFlex}>
        <Button
          shape="circle"
          type="dashed"
          ghost
          icon={isMenuOpen ? <CloseOutlined /> : <MenuOutlined />}
          onClick={() => setIsMenuOpen((open) => !open)}
        />
      </div>
      <Drawer
        visible={isMenuOpen}
        closable={false}
        onClose={() => setIsMenuOpen(false)}
        css={mobileMenuStyle}
      >
        {children}
      </Drawer>
    </>
  );
};

export default MobileMenu;
