import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useSpring, animated, useChain, AnimatedValue } from 'react-spring';
import {
  useDirection,
  useResponsiveWidth,
  useVisibleDiv,
} from '../../../../lib/common/listeners';
import { getRandomColor } from '../../../../lib/common/styles';
import { randomString } from '../../../../lib/common/utils';
import LinearGradient from './LinearGradient';

export interface IArrowLink {
  path: string;
  width: string;
  height: string;
  scale?: number;
  length: string;
  invertedAnimation?: boolean;
  timeFrame?: number;
  timeSteps?: number[];
  horizontalGradient?: boolean;
  visibilityRef?: RefObject<HTMLDivElement>;
}

// INITIAL ARROW WITH CURRENT CONFIG:     config: { mass: 50, tension: 100, friction: 10 },

const ArrowLink: React.FC<IArrowLink> = ({
  path,
  width,
  height,
  length,
  scale = 1,
  invertedAnimation = false,
  timeFrame = 1000,
  timeSteps = [0, 0.9],
  horizontalGradient = true,
  visibilityRef,
}: IArrowLink) => {
  const devRef = useRef<HTMLDivElement>(null);
  const isVisible = useVisibleDiv(visibilityRef ?? devRef);
  const direction = useDirection();

  const opacityRef = useRef(null);
  const opacityProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: isVisible ? 1 : 0 },
    ref: opacityRef,
  });

  const memoX = (invertedAnimation ? -1 : 1) * direction * parseInt(length, 10);

  const propsSvgRef = useRef(null);
  const propsSvg = useSpring<{
    x: number;
    to: { x: number };
  }>({
    x: 0,
    to: {
      x: isVisible ? 0 : memoX,
    },
    from: {
      x: memoX,
    },
    config: {
      mass: 10,
      tension: 100,
      friction: 50,
    },
    reset: !isVisible,
    ref: propsSvgRef,
  });

  useChain([opacityRef, propsSvgRef], timeSteps, timeFrame);

  const gradientId = useMemo(() => randomString(), [isVisible]);

  return (
    <animated.div style={opacityProps} ref={!visibilityRef ? devRef : null}>
      <animated.svg
        strokeDashoffset={propsSvg.x}
        viewBox={`0 0 ${width} ${height}`}
        width={parseInt(width, 10) * scale}
        height={parseInt(height, 10) * scale}
      >
        <LinearGradient
          isVisible={isVisible}
          horizontalGradient={horizontalGradient}
          gradientId={gradientId}
        />
        <path
          strokeDasharray={length}
          strokeWidth="3"
          strokeLinecap="round"
          stroke={`url(#${gradientId})`}
          fill="none"
          d={path}
        />
      </animated.svg>
    </animated.div>
  );
};

export default React.memo(ArrowLink);
