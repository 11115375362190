import { Button, Carousel, Col, Row } from 'antd';
import { css } from '@emotion/react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { descriptionStyle, imageStyle, sectionStyle } from './styles';
import { COLORS } from '../../../../lib/common/styles';
import Text from '../../../../components/Text';

interface IProps {
  name: string;
  link: string;
  images: string[];
}

function Section({ name, link, images }: IProps) {
  return (
    <Row css={sectionStyle}>
      <Col sm={24} md={12} css={descriptionStyle}>
        <Text type="spread-sub-title">{name}</Text>
        <a href={link}>
          <Button type="dashed" shape="round" icon={<ArrowRightOutlined />}>
            Enter Section
          </Button>
        </a>
      </Col>
      <Col sm={24} md={12}>
        <Carousel autoplay effect="fade">
          {images.map((image, i) => (
            <img key={i} css={imageStyle} src={image} alt="" />
          ))}
        </Carousel>
      </Col>
    </Row>
  );
}

export default Section;
