import { css } from '@emotion/react';
import { Button, Drawer, Menu as AntMenu } from 'antd';
import {
  CloseOutlined,
  ContactsOutlined,
  HomeOutlined,
  MenuOutlined,
  ShoppingCartOutlined,
  SnippetsOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import throttle from 'lodash.throttle';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { pathnames } from '../../lib/routes/pathnames';
import { menuStyle, mobileMenuStyle } from './style';
import MobileMenu from './components/MobileMenu';
import { BREAKING_POINT } from '../../lib/common/styles';
import { useMobileView } from '../../lib/common/listeners';

interface IMenuItem {
  name: string;
  path: string;
  subMenus?: IMenuItem[];
  icon?: React.ReactNode;
}

const mainMenuItems: IMenuItem[] = [
  {
    name: 'Home',
    path: pathnames.home,
    icon: <HomeOutlined />,
  },
  {
    name: 'Portfolio',
    path: pathnames.portfolio,
    icon: <SnippetsOutlined />,
    subMenus: [
      {
        name: 'Visual Design',
        path: pathnames.visualDesign,
      },
      {
        name: 'Product Design',
        path: pathnames.productDesign,
      },
      {
        name: 'UX/UI Design',
        path: pathnames.uiUxDesign,
      },
      {
        name: 'Fine Arts',
        path: pathnames.fineArts,
      },
    ],
  },
  {
    name: 'Contact',
    path: pathnames.contact,
    icon: <ContactsOutlined />,
  },
  {
    name: 'Shop',
    path: pathnames.shop,
    icon: <ShoppingCartOutlined />,
  },
];

const Menu = ({ history, location }: RouteComponentProps) => {
  const isMobileView = useMobileView();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const renderMenuItem = (item: IMenuItem) => {
    if (item.subMenus && item.subMenus.length > 0) {
      return (
        <AntMenu.SubMenu
          key={item.path}
          title={item.name}
          icon={isMobileView ? item.icon : undefined}
          onTitleClick={() => history.push(item.path)}
        >
          {item.subMenus.map((subItem) => renderMenuItem(subItem))}
          <AntMenu.Item key={item.path} hidden />
        </AntMenu.SubMenu>
      );
    }

    return (
      <AntMenu.Item key={item.path} icon={isMobileView ? item.icon : undefined}>
        <Link to={item.path}>{item.name}</Link>
      </AntMenu.Item>
    );
  };

  const renderMenu = () => {
    return (
      <AntMenu
        css={isMobileView ? mobileMenuStyle : menuStyle}
        mode={isMobileView ? 'inline' : 'horizontal'}
        inlineCollapsed={isMobileView ? false : undefined}
        selectedKeys={[location.pathname]}
      >
        {mainMenuItems.map((menuItem) => renderMenuItem(menuItem))}
      </AntMenu>
    );
  };

  return (
    <>{isMobileView ? <MobileMenu>{renderMenu()}</MobileMenu> : renderMenu()}</>
  );
};

export default withRouter(Menu);
