import { Row } from 'antd';
import React from 'react';
import { centeredFlex } from '../../lib/common/styles';
import Text from '../Text';
import { imgStyle } from './styles';

interface IProps {
  title: string;
  imgSrc?: string;
  children: React.ReactNode;
}

function SectionDetail({ title, imgSrc, children }: IProps) {
  return (
    <>
      <Row align="middle" justify="center">
        <Text type="main-title">{title}</Text>
      </Row>
      {imgSrc && (
        <div css={centeredFlex}>
          <img src={imgSrc} css={imgStyle} alt="section img" />
        </div>
      )}
      <Text>{children}</Text>
    </>
  );
}

export default SectionDetail;

SectionDetail.defaultProps = {
  imgSrc: undefined,
};
