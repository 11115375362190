import { Card, Row, Col } from 'antd';
import { cardStyle, rightSectionStyle } from './style';
import logo from './assets/logo_white.png';
import Menu from '../../../Menu';

function Header() {
  return (
    <Card css={cardStyle} hoverable bordered={false}>
      <Row>
        <Col span={4}>
          <a href="/">
            <img src={logo} alt="logo" width="50" />
          </a>
        </Col>
        <Col span={20} css={rightSectionStyle}>
          <Menu />
        </Col>
      </Row>
    </Card>
  );
}

export default Header;
