import { css } from '@emotion/react';
import {
  BREAKING_POINT,
  defaultGutter,
  fontRobotoMedium,
  fontRobotoNormal,
  fontSourceSansProBold,
  fontSourceSansProRegular,
  fontSourceSansProSemiBold,
} from '../../lib/common/styles';

export const mainTitleStyle = css`
  ${fontSourceSansProBold};
  letter-spacing: 4.2pt;
  font-size: 2.7vmax;
  margin: ${defaultGutter}px 0;
  @media only screen and (max-width: ${BREAKING_POINT}px) {
    font-size: 28pt;
  }
`;

export const spreadSubTitle = css`
  ${fontRobotoNormal};
  letter-spacing: 4.81pt;
  font-size: 2em;
`;

export const spreadTitleStyle = css`
  ${fontSourceSansProSemiBold};
  letter-spacing: 3.78pt;
  font-size: 2.3vmax;
  margin-top: ${2 * defaultGutter}px;
`;

export const normalTextStyle = css`
  ${fontSourceSansProRegular};
  line-height: 35pt;
  font-size: 1.7em;
`;

export const readableStyle = css`
  ${fontRobotoNormal};
  line-height: 24pt;
  font-size: 1.2em;
`;

export const largeStyle = css`
  ${fontSourceSansProRegular};
  font-size: 2.5vmax;
`;

export const subTitle1Style = css`
  ${fontRobotoMedium};
  line-height: 35pt;
  font-size: 1.5em;
  margin-top: ${3 * defaultGutter}px;
  margin-bottom: ${defaultGutter}px;
`;

export const subTitle2Style = css`
  ${fontRobotoMedium};
  line-height: 35pt;
  font-size: 1.17em;
`;
