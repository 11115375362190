import throttle from 'lodash.throttle';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  RefObject,
  useRef,
} from 'react';
import { BREAKING_POINT } from './styles';

export const useDirection = () => {
  let prevY = window.scrollY;

  const [direction, setDirection] = useState(1);

  const scrollHandler = useCallback(
    throttle(() => {
      setDirection(prevY > window.scrollY ? -1 : 1);
      prevY = window.scrollY;
    }, 700),
    [window.scrollY],
  );

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler, { passive: true });
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return direction;
};

export const useVisibleDiv = (ref: RefObject<HTMLDivElement>) => {
  const [isVisible, setIsVisible] = useState(false);

  const scrollHandler = useCallback(() => {
    if (ref && ref.current) {
      const rect = ref.current.getBoundingClientRect();

      const { innerHeight } = window;
      const documentHeight = document.documentElement.clientHeight;

      setIsVisible(
        rect.top >= 0 && rect.bottom <= (innerHeight || documentHeight),
      );
    }
  }, [window.innerHeight, document.documentElement.clientWidth]);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler, { passive: true });
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return isVisible;
};

export const useMobileView = () => {
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth < BREAKING_POINT,
  );

  const resizeHandler = useCallback(
    throttle(() => {
      setIsMobileView(window.innerWidth < BREAKING_POINT);
    }, 1000),
    [window.innerWidth],
  );

  useEffect(() => {
    window.addEventListener('resize', resizeHandler, { passive: true });
    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  return isMobileView;
};

export type ResponsiveAction = 'stable' | 'grow' | 'shrink';

const BREAK_POINTS = [576, 768, 992, 1200, 1600];

const getRange = (width: number): number | [number, number] => {
  const range: [number, number] = [
    BREAK_POINTS[0],
    BREAK_POINTS[BREAK_POINTS.length - 1],
  ];

  BREAK_POINTS.forEach((bp) => {
    if (bp <= width) {
      range[0] = bp;
    } else if (bp >= width && range[1] >= bp) {
      range[1] = bp;
    }
  });

  if (range[0] === range[1]) {
    return range[0];
  }

  if (range[0] === width || range[1] === width) {
    return width;
  }

  return range;
};

export const useResponsiveWidth = () => {
  const [action, setAction] = useState<ResponsiveAction>('stable');
  const latestRange = useRef<number | [number, number]>(
    getRange(window.innerWidth),
  );

  const resizeHandler = useCallback(
    throttle(() => {
      const range = getRange(window.innerWidth);

      if (typeof latestRange.current !== 'number') {
        if (
          window.innerWidth > latestRange.current[0] &&
          window.innerWidth < latestRange.current[1]
        ) {
          setAction('stable');
        } else if (window.innerWidth > latestRange.current[1]) {
          setAction('grow');
        } else if (window.innerWidth < latestRange.current[0]) {
          setAction('shrink');
        }
      } else if (typeof latestRange === 'number') {
        if (window.innerWidth === latestRange) {
          setAction('stable');
        } else if (
          window.innerWidth < latestRange &&
          latestRange !== BREAK_POINTS[0]
        ) {
          setAction('shrink');
        } else if (
          window.innerWidth > latestRange &&
          latestRange !== BREAK_POINTS[BREAK_POINTS.length - 1]
        ) {
          setAction('grow');
        }
      }

      latestRange.current = range;
    }, 1000),
    [window.innerWidth],
  );

  useEffect(() => {
    window.addEventListener('resize', resizeHandler, { passive: true });
    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  return action;
};

export const useMappedWidthScale = (
  minWidth: number,
  maxWidth: number,
  minScale: number,
  maxScale: number,
) => {
  const calculateScale = (width: number) => {
    const widthSpan = maxWidth - minWidth;
    const scaleSpan = maxScale - minScale;

    return minScale + ((width - minWidth) / widthSpan) * scaleSpan;
  };

  const [scale, setScale] = useState<number>(calculateScale(window.innerWidth));

  const resizeHandler = useCallback(
    throttle(() => {
      if (window.innerWidth <= minWidth) {
        setScale(minScale);
        return;
      }

      if (window.innerWidth >= maxWidth) {
        setScale(maxScale);
        return;
      }

      setScale(calculateScale(window.innerWidth));
    }, 1000),
    [window.innerWidth],
  );

  useEffect(() => {
    window.addEventListener('resize', resizeHandler, { passive: true });
    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  return scale;
};
