import { Row } from 'antd';
import React from 'react';
import Text from '../../components/Text';

function Shop() {
  return (
    <Row align="middle" justify="center">
      <Text type="main-title">Coming Soon...</Text>
    </Row>
  );
}

export default Shop;
