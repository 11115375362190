import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import { Row, Col, Popover } from 'antd';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  marginedRow,
  mobileViewContainer,
  revertedStyle,
  Year,
} from './styles';
import ArrowLink, { IArrowLink } from '../ArrowLink';
import {
  centeredFlex,
  FlexedColumn,
  getRandomColor,
  getRandomCssColor,
} from '../../../../lib/common/styles';
import Details from './components/Details';
import Title from './components/Title';
import SectionImage from './components/SectionImage';
import { useDirection, useVisibleDiv } from '../../../../lib/common/listeners';
import SectionDescription from './components/SectionDescription';
import MiddleSection from './components/MiddleSection';

interface IProps {
  year: string;
  title: string;
  description: string;
  followLink: string;
  imgSrc: string;
  arrowLink: Omit<IArrowLink, 'invertedAnimation' | 'isVisible'>;
  reverted: boolean;
}

const TimelineEntry = ({
  year,
  title,
  description,
  followLink,
  imgSrc,
  reverted,
  arrowLink,
}: IProps) => {
  const inputRef = useRef<HTMLDivElement>(null);
  const isVisible = useVisibleDiv(inputRef);

  const direction = useDirection();

  const props = useSpring({
    from: { opacity: 0 },
    to: { opacity: isVisible ? 1 : 0.1 },
  });

  return (
    <animated.div style={props}>
      <Row
        css={[
          mobileViewContainer,
          centeredFlex,
          marginedRow,
          reverted ? revertedStyle : undefined,
        ]}
        ref={inputRef}
      >
        <SectionDescription
          year={year}
          title={title}
          description={description}
          followLink={followLink}
        />
        <MiddleSection
          height={arrowLink.height}
          width={arrowLink.width}
          path={arrowLink.path}
          length={arrowLink.length}
          reverted={reverted}
          horizontalGradient
          visibilityRef={inputRef}
        />
        <SectionImage imgSrc={imgSrc} />
      </Row>
    </animated.div>
  );
};

export default React.memo(TimelineEntry);
