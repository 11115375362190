import { css } from '@emotion/react';
import React from 'react';
import { centeredFlex } from '../../../lib/common/styles';
import ArrowLink, { IArrowLink } from './ArrowLink';

const initialArrow: Omit<IArrowLink, 'invertedAnimation' | 'isVisible'> = {
  path:
    'M 28 28 C 48.16 109.9 207.76 102.76 252.56 141.12 C 301.7 183.12 274.68 254.24 274.68 254.24',
  width: '300',
  height: '270',
  length: '384',
};

const InitialArrow: React.FC = () => {
  return (
    <div
      css={[
        centeredFlex,
        css`
          width: fit-content;
          position: relative;
          left: 50%;
          margin-bottom: -155px;
        `,
      ]}
    >
      <ArrowLink
        path={initialArrow.path}
        width={initialArrow.width}
        height={initialArrow.height}
        length={initialArrow.length}
        scale={1.2}
      />
    </div>
  );
};

export default React.memo(InitialArrow);
