import { ExportOutlined } from '@ant-design/icons';
import { css, SerializedStyles } from '@emotion/react';
import { Button, Spin } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import 'react-pdf/dist/umd/Page/AnnotationLayer.css';
import { centeredFlex } from '../../lib/common/styles';
import Navigation from './components/Navigation';
import { exportBtnStyle } from './styles';

interface IProps {
  src: string;
  scale?: number;
  pageCSS?: SerializedStyles;
  withZoom?: boolean;
}

const PDF: React.FC<IProps> = ({
  src,
  scale = 1,
  pageCSS = undefined,
  withZoom = false,
}: IProps) => {
  const [pages, setPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [visible, setVisible] = useState(false);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setPages(numPages);
  };

  const renderLoadingSpinner = () => (
    <div css={centeredFlex}>
      <Spin />
    </div>
  );

  const file = useMemo(() => src, [src]);

  useEffect(() => {
    setTimeout(() => setVisible(true), 1000);
  });

  return (
    <>
      {!visible ? (
        renderLoadingSpinner()
      ) : (
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          externalLinkTarget="_blank"
          renderMode="svg"
          loading={renderLoadingSpinner()}
        >
          <Page
            pageIndex={pageNumber}
            css={[
              css`
                width: 100%;
                overflow: auto;
              `,
              pageCSS,
            ]}
            scale={scale}
            renderTextLayer={false}
            pageNumber={pageNumber}
          />
          <a href={src} target="_blank" rel="noreferrer">
            <Button
              css={exportBtnStyle}
              type="primary"
              shape="circle"
              icon={<ExportOutlined />}
            />
          </a>
          {pages > 1 && (
            <Navigation
              numPages={pages}
              currentPage={pageNumber}
              onLeftClick={() => setPageNumber(pageNumber - 1)}
              onRightClick={() => setPageNumber(pageNumber + 1)}
            />
          )}
        </Document>
      )}
    </>
  );
};

export default PDF;

PDF.defaultProps = {
  scale: 1,
  pageCSS: undefined,
  withZoom: false,
};
