import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { defaultGutter } from '../../../../lib/common/styles';

export const ContactItem = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-right: ${defaultGutter}px;
  }
`;

export const iconStyle = css`
  font-size: 30px;
`;
