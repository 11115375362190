import { css } from '@emotion/react';
import { defaultGutter } from '../../../../lib/common/styles';

export const navigationStyle = css`
  position: absolute;
  top: ${defaultGutter}px;
  left: ${3 * defaultGutter}px;

  .ant-card-body {
    padding: 0px;
  }
`;
