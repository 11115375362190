import { Col } from 'antd';
import React, { useMemo, useRef } from 'react';
import { useVisibleDiv } from '../../../../../lib/common/listeners';
import {
  FlexedColumn,
  getRandomColor,
  getRandomCssColor,
} from '../../../../../lib/common/styles';
import { TitleWrapper, Year } from '../styles';
import Details from './Details';
import Title from './Title';

interface IProps {
  year: string;
  title: string;
  description: string;
  followLink: string;
}

const SectionDescription: React.FC<IProps> = ({
  year,
  title,
  description,
  followLink,
}: IProps) => {
  const inputRef = useRef<HTMLDivElement>(null);
  const isVisible = useVisibleDiv(inputRef);
  const color = useMemo(() => getRandomCssColor(getRandomColor()), [isVisible]);

  return (
    <Col sm={24} md={11} ref={inputRef}>
      <FlexedColumn>
        <Year css={color}>{year}</Year>
        <Title>{title}</Title>
        <Details description={description} followLink={followLink} />
      </FlexedColumn>
    </Col>
  );
};

export default React.memo(SectionDescription);
