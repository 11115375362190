import React from 'react';
import { TitleWrapper } from '../styles';

interface IProps {
  children: React.ReactNode;
}

/*
 
 font-family: 'Roboto', sans-serif;
     font-weight: 400;
 */

const Title: React.FC<IProps> = ({ children }: IProps) => {
  return <TitleWrapper>{children}</TitleWrapper>;
};

export default React.memo(Title);
