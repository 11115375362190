import React from 'react';
import { ReactComponent as Icon404 } from '../../assets/404.svg';

import './error404.css';

export const Error404: React.FC = () => {
  return (
    <div className="error-page">
      <Icon404 />
    </div>
  );
};
