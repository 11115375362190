import { Col, Row } from 'antd';
import React from 'react';
import { useSpring, animated } from 'react-spring';
import greetings from '../../../assets/greetings_short.png';
import Text from '../../../components/Text';

function Greetings() {
  const props = useSpring({ opacity: 1, from: { opacity: 0 } });
  return (
    <animated.div style={props}>
      <Row justify="center" align="middle">
        <Col sm={24} md={10}>
          <img src={greetings} alt="greetings" width="100%" />
        </Col>
        <Col sm={24} md={14}>
          <Text type="large">
            <div>
              <>Hi, I am </>
              <b>Ansem Ben Romdhane</b>
            </div>
            <div>
              <>a multi-disciplinary designer specializing in </>
              <b>UX and Visual Design.</b>
            </div>
          </Text>
        </Col>
      </Row>
    </animated.div>
  );
}

export default Greetings;
