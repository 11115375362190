import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { Button, Card } from 'antd';
import React from 'react';
import { centeredFlex, defaultGutter } from '../../../../lib/common/styles';
import { navigationStyle } from './styles';

interface IProps {
  numPages: number;
  currentPage: number;
  onLeftClick: () => void;
  onRightClick: () => void;
}

const renderText = (currentPage: number, numPages: number) =>
  `${currentPage} of ${numPages}`;

const Navigation: React.FC<IProps> = ({
  numPages,
  currentPage,
  onLeftClick,
  onRightClick,
}: IProps) => {
  return (
    <Card hoverable css={navigationStyle}>
      <div css={centeredFlex}>
        <Button
          disabled={currentPage === 1}
          icon={<LeftOutlined />}
          onClick={onLeftClick}
        />
        <div
          css={css`
            padding: 0px ${defaultGutter}px;
          `}
        >
          {renderText(currentPage, numPages)}
        </div>
        <Button
          disabled={currentPage === numPages}
          icon={<RightOutlined />}
          onClick={onRightClick}
        />
      </div>
    </Card>
  );
};

export default Navigation;
