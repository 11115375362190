/**
 * This file contains the mapping between the pathnames and the existing pages.
 */
import { RouteProps } from 'react-router-dom';
import Contact from '../../pages/Contact';
import Home from '../../pages/Home';
import Portfolio from '../../pages/Portfolio';
import VisualDesignPage from '../../pages/Portfolio/pages/VisualDesign';
import ProductDesignPage from '../../pages/Portfolio/pages/ProductDesign';
import Shop from '../../pages/Shop';
import { pathnames } from './pathnames';
import UxUiDesignPage from '../../pages/Portfolio/pages/UxUiDesign';
import FineArts from '../../pages/Portfolio/pages/FineArts';
import MechanicalEngineerPage from '../../pages/MechanicalEngineer';
import IndustrialDesignPage from '../../pages/IndustrialDesign';
import ExploreMyselfPage from '../../pages/ExploreMyself';
import DigitalMarketingPage from '../../pages/DigitalMarketing';

interface IRoute extends RouteProps {
  // Other fields to configure the routes
  foo?: string;
}

export const routes: IRoute[] = [
  {
    component: Home,
    exact: true,
    path: pathnames.home,
  },
  {
    component: Portfolio,
    exact: true,
    path: pathnames.portfolio,
  },
  {
    component: Contact,
    exact: true,
    path: pathnames.contact,
  },
  {
    component: Shop,
    exact: true,
    path: pathnames.shop,
  },
  {
    component: VisualDesignPage,
    exact: true,
    path: pathnames.visualDesign,
  },
  {
    component: ProductDesignPage,
    exact: true,
    path: pathnames.productDesign,
  },
  {
    component: UxUiDesignPage,
    exact: true,
    path: pathnames.uiUxDesign,
  },
  {
    component: FineArts,
    exact: true,
    path: pathnames.fineArts,
  },
  {
    component: MechanicalEngineerPage,
    exact: true,
    path: pathnames.mechanicalEngineer,
  },
  {
    component: IndustrialDesignPage,
    exact: true,
    path: pathnames.industrialDesign,
  },
  {
    component: ExploreMyselfPage,
    exact: true,
    path: pathnames.exploreMyself,
  },
  {
    component: DigitalMarketingPage,
    exact: true,
    path: pathnames.digitalMarketing,
  },
];
