import { Col, Row } from 'antd';
import throttle from 'lodash.throttle';
import { css } from '@emotion/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Greetings from './components/Greetings';
import TimelineEntry from './components/TimelineEntry';
import { entryData, intermediateArrows } from './data';
import ArrowLink, { IArrowLink } from './components/ArrowLink';
import { centeredFlex } from '../../lib/common/styles';
import { IntermediateLinkDiv, lefty, righty } from './styles';
import InitialArrow from './components/InitialArrow';
import Text from '../../components/Text';
import { useMappedWidthScale, useMobileView } from '../../lib/common/listeners';

function Home() {
  const isMobileView = useMobileView();

  const scale = useMappedWidthScale(900, 1770, 1.1, 1.5);

  return (
    <>
      <Greetings />
      <Row align="middle" justify="center">
        <Text type="spread-title">My Story</Text>
      </Row>
      {!isMobileView && <InitialArrow />}
      {entryData.map((entry, i) => (
        <div
          key={i}
          css={css`
            position: relative;
          `}
        >
          <TimelineEntry
            year={entry.year}
            title={entry.title}
            description={entry.description}
            followLink={entry.followLink}
            imgSrc={entry.img}
            arrowLink={entry.arrowLink}
            reverted={i % 2 === 1}
          />
          {!isMobileView && intermediateArrows.length > i && (
            <IntermediateLinkDiv css={i % 2 === 0 ? lefty : righty}>
              <ArrowLink
                path={intermediateArrows[i].path}
                height={intermediateArrows[i].height}
                width={intermediateArrows[i].width}
                length={intermediateArrows[i].length}
                horizontalGradient={false}
                scale={scale}
                timeFrame={2000}
                timeSteps={[0, 1]}
              />
            </IntermediateLinkDiv>
          )}
        </div>
      ))}
    </>
  );
}

export default Home;
