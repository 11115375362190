import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const IntermediateLinkDiv = styled.div`
  position: absolute;
  top: 75%;
`;

export const lefty = css`
  left: 7%;
`;

export const righty = css`
  right: 7%;
`;
