import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { COLORS, fontSourceSansProLight } from '../../../../lib/common/styles';

export const footerStyle = css`
  background: ${COLORS.lighterGrey};
  line-height: 3;
  ${fontSourceSansProLight};
`;

export const SignatureDiv = styled.div`
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
`;
