import { css } from '@emotion/react';
import React, { useEffect, useMemo } from 'react';
import {
  largeStyle,
  mainTitleStyle,
  normalTextStyle,
  readableStyle,
  spreadSubTitle,
  spreadTitleStyle,
  subTitle1Style,
  subTitle2Style,
} from './styles';

interface IProps {
  type?:
    | 'main-title'
    | 'spread-title'
    | 'spread-sub-title'
    | 'sub-title-1'
    | 'sub-title-2'
    | 'normal'
    | 'readable'
    | 'large';
  children: React.ReactNode;
}

const Text: React.FC<IProps> = ({ type = 'normal', children }: IProps) => {
  const textStyle = useMemo(() => {
    return {
      normal: normalTextStyle,
      'main-title': mainTitleStyle,
      'spread-sub-title': spreadSubTitle,
      'spread-title': spreadTitleStyle,
      readable: readableStyle,
      large: largeStyle,
      'sub-title-1': subTitle1Style,
      'sub-title-2': subTitle2Style,
    };
  }, [type]);

  return <div css={textStyle[type]}>{children}</div>;
};

Text.defaultProps = {
  type: 'normal',
};

export default React.memo(Text);
