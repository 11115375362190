import { Row, Col, Image } from 'antd';
import SectionDetail from '../../components/SectionDetail';
import { defaultGutter } from '../../lib/common/styles';
import digitalMarketer from '../../assets/digital_marketing.png';
import nanoDegree from './assets/nano_degree.png';
import sem from './assets/SEM.png';
import semPDF from './assets/sem.pdf';
import PDF from '../../components/PDF';
import seo from './assets/SEO.png';
import googleAds from './assets/google_ads.png';
import mailchimp from './assets/mailchimp.jpg';
import googleAnalytics from './assets/google_analytics.png';
import Text from '../../components/Text';

const nanodegreeDescription = `This nanodegree covers all of the fundamentals in an applied, project-based learning environment. It is great to gain experience running real campaings. It was a great way to complement marking courses taken at school by gaining the hands-on experience running real campaings, as required in certain marketing jobs. I was greatful that I explored what skills a creer in digital marketing involves. Digital Marketing is an exciting field to explore, and the practical learning environment at Udacity helped me in creating marketing content, using social media to amplify a message, making content discoverable in search, running Ads campaings on Facebook, email marketing and measuring and optimizing with Google Analytics.`;

const semDescription = `Cutting through the noise in social media can be challenging, and often, marketers must use paid social media marketing strategies to amplify their message. The reason why I chose Facebook is because it's easy to reshare, resend posts and interact with each other about my content (comments, links, likes...).
`;

const seoDescription = `Search engines are an essential part of the online experience. Learn how to optimize your search engine presence through on-site and off-site activities, including how to develop your target keyword list, optimize your website UX and design, and execute a link-build campaign.`;

const googleAdsDescription = `Optimizing visibility in search engine results in an essential part of digital marketing. Reinforcing findability through Search Engine Marketing (SEM) is an effective tactic to acheive your marketing objectives. In this course, you learn how to create, execute, and optimize and effective ad campaign using Ads by Google.`;

const emailMarketingDescription = `Email is an effective marketing channel, especially at the conversion and retention stage of the customer journey. In this course, you learn how to create an email marketing strategy, create and execute email campaigns, and measure the results`;

const googleAnalyticsDescription = `Actions online can be tracked, and so can the effect of your digital marketing efforts. In this course, you learn to use Google Analytics to evaluate your audience, measure the success of your acquisition and engagement efforts, evaluate your user's conversions to your goals, and use those insights to plan and optimize your marketing budgets.`;

function DigitalMarketingPage() {
  return (
    <SectionDetail title="As a Digital Marketer" imgSrc={digitalMarketer}>
      <Row gutter={[defaultGutter, defaultGutter]}>
        <Col sm={0} md={3} />
        <Col sm={24} md={18}>
          <Image src={nanoDegree} />
        </Col>
        <Col sm={0} md={3} />
      </Row>
      <Row>
        <p>{nanodegreeDescription}</p>
      </Row>
      <Row>
        <Text type="sub-title-1">Main Projects</Text>
      </Row>
      <Row gutter={[defaultGutter, defaultGutter]}>
        <Col sm={0} md={3} />
        <Col sm={24} md={18}>
          <Image src={sem} preview={false} />
        </Col>
        <Col sm={0} md={3} />
      </Row>
      <Row>
        <Text type="sub-title-2">
          Social Media Advertising with Facebook Blueprint (SEM)
        </Text>
      </Row>
      <Row>
        <p>{semDescription}</p>
      </Row>
      <Row gutter={[defaultGutter, defaultGutter]}>
        <Col sm={0} md={3} />
        <Col sm={24} md={18}>
          <PDF src={semPDF} />
        </Col>
        <Col sm={0} md={3} />
      </Row>
      <Row gutter={[defaultGutter, defaultGutter]}>
        <Col sm={0} md={3} />
        <Col sm={24} md={18}>
          <Image src={seo} preview={false} />
        </Col>
        <Col sm={0} md={3} />
      </Row>
      <Row>
        <Text type="sub-title-2">Search Engine Optimization (SEO)</Text>
      </Row>
      <Row>
        <p>{seoDescription}</p>
      </Row>
      <Row gutter={[defaultGutter, defaultGutter]}>
        <Col sm={0} md={3} />
        <Col sm={24} md={18}>
          <Image src={googleAds} preview={false} />
        </Col>
        <Col sm={0} md={3} />
      </Row>
      <Row>
        <Text type="sub-title-2">Search Engine Marketing with Google Ads</Text>
      </Row>
      <Row>
        <p>{googleAdsDescription}</p>
      </Row>
      <Row gutter={[defaultGutter, defaultGutter]}>
        <Col sm={0} md={7} />
        <Col sm={24} md={10}>
          <Image src={mailchimp} preview={false} />
        </Col>
        <Col sm={0} md={7} />
      </Row>
      <Row>
        <Text type="sub-title-2">Email Marketing</Text>
      </Row>
      <Row>
        <p>{emailMarketingDescription}</p>
      </Row>
      <Row gutter={[defaultGutter, defaultGutter]}>
        <Col sm={0} md={7} />
        <Col sm={24} md={10}>
          <Image src={googleAnalytics} preview={false} />
        </Col>
        <Col sm={0} md={7} />
      </Row>
      <Row>
        <Text type="sub-title-2">
          Measure and Optimize with Google Analytics
        </Text>
      </Row>
      <Row>
        <p>{googleAnalyticsDescription}</p>
      </Row>
    </SectionDetail>
  );
}

export default DigitalMarketingPage;
