import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const COLORS = {
  olive: '#A3BCB6',
  greenLeaf: '#39603D',
  brownGrey: '#3C403D',
  tanly: '#DADED4',
  white: '#FFFFFF',
  greyGreen: '#e1e7e2',
  orangish: '#f0cdb7',
  offWhite: '#fafafa',
  blackThree: '#333333',
  darkGrey: '#9c9696',
  lightGrey: '#bfbfbf',
  lighterGrey: '#ededed',
};

export const GUTTER = {
  small: '12',
  normal: '24',
  large: '48',
};

export const defaultGutter = 24;

export const centeredFlex = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FlexedColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BREAKING_POINT = 750;

export const RANDOM_COLORS = [
  '#f58529',
  '#feda77',
  '#dd2a7b',
  '#8134af',
  '#515bd4',
];

export const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * max) + min;
};

export const getRandomColor = (): string => {
  return RANDOM_COLORS[getRandomNumber(0, RANDOM_COLORS.length)];
};

export const getRandomCssColor = (color: string) => css`
  color: ${color};
`;

export const fontRobotoNormal = css`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
`;

export const fontRobotoMedium = css`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
`;

export const fontSourceSansProLight = css`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
`;

export const fontSourceSansProRegular = css`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
`;

export const fontSourceSansProSemiBold = css`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
`;

export const fontSourceSansProBold = css`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
`;
