export const pathnames = {
  home: '/',
  portfolio: '/portfolio',
  contact: '/contact',
  shop: '/shop',
  visualDesign: '/visual-design',
  productDesign: '/product-design',
  uiUxDesign: '/ui-ux-design',
  fineArts: '/fine-arts',
  mechanicalEngineer: '/mechanical-engineer',
  industrialDesign: '/industrial-design',
  exploreMyself: '/explore-myself',
  digitalMarketing: '/digital-marketing',
};
