import { Col, Row, Image } from 'antd';
import SectionDetail from '../../components/SectionDetail';
import mechanicalEngineer from '../../assets/graduation.png';
import { defaultGutter } from '../../lib/common/styles';

import diploma from './assets/originals/diploma.png';
import melangeur from './assets/originals/melangeur.png';
import ribbonMix from './assets/originals/ribbon_mix.jpg';

import diplomaC from './assets/compressed/diploma.jpg';
import melangeurC from './assets/compressed/melangeur.png';
import ribbonMixC from './assets/compressed/ribbon_mix.jpg';

import sfe from './assets/sfe.pdf';
import PDF from '../../components/PDF';
import Text from '../../components/Text';

const description = `I majored in mechanical engineering and specialized in Design and Manufacturing. The main subjects were:`;

const projectDescription =
  'In my graduation project, I worked in a cross functional team to upgrade the CAD design of an Industrial Ribbon Mixer using SolidWorks, Catia, and MasterCam. Before manufacturing, I simulated and tested the impact of material resistance. This design added value to the industry money wise, for the environment and for the quality of the product.';

function MechanicalEngineerPage() {
  return (
    <SectionDetail title="As a Mechanical Engineer" imgSrc={mechanicalEngineer}>
      <Row>
        <Text type="sub-title-1">Academic Experience</Text>
      </Row>
      <Row>{description}</Row>
      <Row>
        <ul>
          <li>CADs</li>
          <li>Product management</li>
          <li>Calculus</li>
          <li>Hydraulics</li>
          <li>Computer Science</li>
        </ul>
      </Row>
      <br />
      <Row gutter={[defaultGutter, defaultGutter]}>
        <Col sm={0} md={3} />
        <Col sm={24} md={18}>
          <Image src={diplomaC} preview={{ src: diploma }} />
        </Col>
        <Col sm={0} md={3} />
      </Row>
      <Row>
        <Text type="sub-title-1">Project</Text>
      </Row>
      <Row>
        <p>{projectDescription}</p>
      </Row>
      <Row gutter={[defaultGutter, defaultGutter]}>
        <Col sm={0} md={3} />
        <Col sm={24} md={18}>
          <Image src={melangeurC} preview={{ src: melangeur }} />
        </Col>
        <Col sm={0} md={3} />
      </Row>
      <Row gutter={[defaultGutter, defaultGutter]}>
        <Col sm={0} md={3} />
        <Col sm={24} md={18}>
          <Image src={ribbonMixC} preview={{ src: ribbonMix }} />
        </Col>
        <Col sm={0} md={3} />
      </Row>
      <Row gutter={[defaultGutter, defaultGutter]}>
        <Col sm={0} md={3} />
        <Col sm={24} md={18}>
          <PDF src={sfe} />
        </Col>
        <Col sm={0} md={3} />
      </Row>
    </SectionDetail>
  );
}

export default MechanicalEngineerPage;
