import { Button, Col, Image, Row } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import React from 'react';
import ContactCard from './components/ContactCard';
import cv from './assets/cv.pdf';

import cvImg from './assets/originals/cv-1.jpg';
import cvImgC from './assets/compressed/cv-1.jpg';

import rightImage from './assets/originals/me.png';
import rightImageC from './assets/compressed/me.jpg';

import { defaultGutter } from '../../lib/common/styles';

function Contact() {
  return (
    <Row gutter={[defaultGutter, defaultGutter]}>
      <Col md={24} lg={12}>
        <Image src={cvImgC} preview={{ src: cvImg }} />
        <a href={cv} target="_blank" rel="noreferrer">
          <Button type="ghost" icon={<DownloadOutlined />}>
            Download
          </Button>
        </a>
      </Col>
      <Col md={24} lg={12}>
        <Image src={rightImageC} preview={{ src: rightImage }} />
        <ContactCard />
      </Col>
    </Row>
  );
}

export default Contact;
