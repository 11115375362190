import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  BREAKING_POINT,
  centeredFlex,
  defaultGutter,
} from '../../../../lib/common/styles';

export const descriptionStyle = css`
  ${centeredFlex};
  flex-direction: column;
`;

export const imageStyle = css`
  max-width: 100%;
  height: auto;
`;

export const sectionStyle = css`
  margin: ${defaultGutter * 10}px 0;
  @media only screen and (max-width: ${BREAKING_POINT + 50}px) {
    flex-direction: column-reverse;
  }
`;
