import { Col } from 'antd';
import React from 'react';
import { centeredFlex } from '../../../../../lib/common/styles';
import { TitleWrapper } from '../styles';

interface IProps {
  imgSrc: string;
}
const SectionImage: React.FC<IProps> = ({ imgSrc }: IProps) => {
  return (
    <Col sm={24} md={11} css={centeredFlex}>
      <img src={imgSrc} alt="section" width={400} />
    </Col>
  );
};

export default React.memo(SectionImage);
