import { css } from '@emotion/react';
import { BREAKING_POINT, COLORS } from '../../lib/common/styles';

export const menuStyle = css`
  background: transparent;
  border-bottom: none;
  color: ${COLORS.lightGrey};
  a {
    color: ${COLORS.lightGrey} !important;
  }
`;

export const mobileMenuStyle = css`
  .ant-menu {
    color: black !important;
  }
  svg {
    fill: black !important;
  }
  a {
    color: black !important;
  }

  .ant-menu-submenu-title:hover {
    color: black !important;
    background-color: ${COLORS.lightGrey};
  }

  .ant-menu-submenu-selected {
    color: black !important;
  }
`;
