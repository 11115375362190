import { Button, Popover } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';
import { FlexedColumn } from '../../../../../../lib/common/styles';
import { popoverStyle } from './styles';
import Text from '../../../../../../components/Text';
import { useMobileView } from '../../../../../../lib/common/listeners';

interface IProps {
  description: string;
  followLink: string;
}

const Details: React.FC<IProps> = ({ description, followLink }: IProps) => {
  const isMobileView = useMobileView();
  const renderDescription = useCallback(
    () => (
      <FlexedColumn>
        <Text type="readable">{description}</Text>
        <a href={followLink}>
          <Button type="dashed" shape="round" icon={<ArrowRightOutlined />}>
            Check Details
          </Button>
        </a>
      </FlexedColumn>
    ),
    [description, followLink],
  );

  return (
    <Popover
      overlayStyle={popoverStyle}
      placement={isMobileView ? 'top' : 'bottom'}
      content={renderDescription()}
      trigger="click"
    >
      <Button type="dashed" shape="round">
        Show More
      </Button>
    </Popover>
  );
};

export default React.memo(Details);
