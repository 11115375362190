import me from '../../assets/graduation.png';
import indiDesign from '../../assets/industrial_design.png';
import exploreMyself from '../../assets/explore.png';
import dreamJob from '../../assets/visual_design.png';
import digitalMarketing from '../../assets/digital_marketing.png';
import uxui from '../../assets/ux_ui.png';
import { IArrowLink } from './components/ArrowLink';
import { pathnames } from '../../lib/routes/pathnames';

interface IEntryData {
  year: string;
  title: string;
  description: string;
  followLink: string;
  img: string;
  arrowLink: Omit<IArrowLink, 'invertedAnimation' | 'isVisible'>;
}

export const entryData: IEntryData[] = [
  {
    year: '2018',
    title: 'Graduated in Mechanical Engineering',
    img: me,
    followLink: pathnames.mechanicalEngineer,
    description: `
      It all started with my passion for fixing stuff around the house; my bycicle, the home appliences... I studied mechanical engineering in college, to learn more about the computational mechanics. Along the way, I mastered CAD tools which made me appreciate industrial machines design.
    `,
    arrowLink: {
      path: 'M 100 10 c -19.8 17.4 -59.6 29 -87.4 27.2',
      width: '105',
      height: '50',
      length: '94',
    },
  },
  {
    year: '2019',
    title: 'Worked as Industrial Designer',
    img: indiDesign,
    followLink: pathnames.industrialDesign,
    description: `
      Rightaway after graduating, I got offered a job opportunity in this beautiful field of industrial design using CAD software where I dived into the professional word and the topics about why each company decided to release products and how they compete with each other interested me a lot.
    `,
    arrowLink: {
      path: 'M 100 20 c -26.9 3.1 -71.5 0.4 -91.1 -9.3',
      width: '110',
      height: '30',
      length: '93',
    },
  },
  {
    year: '2019',
    title: 'A Journey to Explore Myself',
    img: exploreMyself,
    followLink: pathnames.exploreMyself,
    description: `
      I always had the thrills to travel and to learn about new places and more importantly about myself and I admit it was the best decision I took.
    `,
    arrowLink: {
      path: 'M 90 10 c -14.1 6.5 -54.5 15.1 -78.9 12.7',
      width: '100',
      height: '30',
      length: '81',
    },
  },
  {
    year: '2019',
    title: 'Persued the field of my Dream Job',
    img: dreamJob,
    followLink: pathnames.visualDesign,
    description: `
      We evolve, and changing disciplines is just giving in to change in life. It was a risky choice but I have always been passionate about art and crafting. Thus, I followed my intuition to a better journey in my life then learnt about graphic design and I kept practicing to refine my talent and thrive.
    `,
    arrowLink: {
      path: 'M 74 17 C 55 12 37 10 10 10',
      width: '80',
      height: '20',
      length: '65',
    },
  },
  {
    year: '2020',
    title: 'Learnt Digital Marketing',
    img: digitalMarketing,
    followLink: pathnames.digitalMarketing,
    description: `
      Along the way, I got a Digital Marketing Nanodegree to learn how companies promote their products. I realized that good marketing alone could not make a product succeed, it has to have good product design. Learning Digital Marketing also made me a designer who pay attention to metrics. 
    `,
    arrowLink: {
      path: 'M 110 20 c -33 5.2 -57.4 3.6 -89.3 0',
      width: '130',
      height: '40',
      length: '90',
    },
  },
  {
    year: '2020',
    title: 'Upgrade to UX/UI Design',
    img: uxui,
    followLink: pathnames.uiUxDesign,
    description: `
      Throughout my experience with being a multi branched designer, I realised that understanding the users needs is the most crucial step during the process. The passion and practice of interviewing people helped me get used to it. So I knew then that I wanted to be involved in the entire process of product development.
    `,
    arrowLink: {
      path: 'M 70 15 c -21.6 3.2 -54.4 1.2 -65.6 -7.5',
      width: '75',
      height: '20',
      length: '68',
    },
  },
];

export const intermediateArrows: Omit<
  IArrowLink,
  'invertedAnimation' | 'isVisible'
>[] = [
  {
    path: 'M 40 10 c -14 9.9 -53.1 55.8 6.3 110.9',
    width: '60',
    height: '130',
    length: '129',
  },
  {
    path: 'M 10 10 c 14.5 8.9 90.2 53.7 37.3 122.2',
    width: '80',
    height: '140',
    length: '150',
  },
  {
    path: 'M 40 10 c -14 9.9 -59.4 61.1 0 116.2',
    width: '50',
    height: '130',
    length: '135',
  },
  {
    path: 'M 10 10 c 14.5 8.9 90.2 53.7 37.3 122.2',
    width: '70',
    height: '140',
    length: '150',
  },
  {
    path: 'M 50 10 c -14 9.9 -59.4 61.1 0 116.2',
    width: '70',
    height: '130',
    length: '135',
  },
];
