import React from 'react';
import dribble from './assets/dribble.png';
import instagram from './assets/instagram.png';
import linkedin from './assets/linkedin.png';
import pinterest from './assets/pinterest.png';
import { Container } from './styles';

function Platforms() {
  return (
    <Container>
      <a href="https://dribbble.com/ansem996" target="_blank" rel="noreferrer">
        <img src={dribble} alt="dribble" width="30" />
      </a>
      <a
        href="https://www.instagram.com/nsmfewartwrk/"
        target="_blank"
        rel="noreferrer"
      >
        <img src={instagram} alt="instagram" width="30" />
      </a>
      <a
        href="https://www.linkedin.com/in/ansem-br/"
        target="_blank"
        rel="noreferrer"
      >
        <img src={linkedin} alt="linkedin" width="40" />
      </a>
      <a
        href="https://www.pinterest.com/ansembenromdhan/"
        target="_blank"
        rel="noreferrer"
      >
        <img src={pinterest} alt="pinterest" width="40" />
      </a>
    </Container>
  );
}

export default Platforms;
