import { BrowserRouter, Route, Router, Switch } from 'react-router-dom';
import { useEffect } from 'react';
import { routes } from './routes';
import { Error404 } from '../../pages/Error404';
import { LayoutDiv } from '../../components/Layout/styles';
import Header from '../../components/Layout/components/header';
import Footer from '../../components/Layout/components/footer';
import Main from '../../components/Layout/components/main';

const Routes = () => (
  <Switch>
    {routes.map(({ path, component, exact }, index) => (
      <Route
        key={index}
        exact={exact}
        path={path}
        component={component}
        sensitive
      />
    ))}
    <Route component={Error404} />
  </Switch>
);

export const RouterConfig: React.FC = () => {
  return (
    <BrowserRouter>
      <LayoutDiv>
        <Header />
        <Main>
          <Routes />
        </Main>
        <Footer />
      </LayoutDiv>
    </BrowserRouter>
  );
};
