import { Col, Row } from 'antd';
import React from 'react';
import { ContentWrapper, mainContentStyle } from './style';

interface IProps {
  children: React.ReactNode;
}

function Main({ children }: IProps) {
  return (
    <ContentWrapper>
      <div>
        <Row>
          <Col xs={0} sm={2} md={4} />
          <Col xs={24} sm={20} md={16} css={mainContentStyle}>
            {children}
          </Col>
          <Col xs={0} sm={2} md={4} />
        </Row>
      </div>
    </ContentWrapper>
  );
}

export default Main;
