import i18next from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// import * as moment from 'moment';
import { en } from './en';

export const i18n = i18next
  .use(initReactI18next)
  // .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: true,
    },
    resources: {
      en,
    },
  });

// uncomment if you are using moment
/*
(async () => {
  const t = await i18n;
  
  // set moment locale
  moment.locale(i18next.language);
  i18next.on('languageChanged', moment.locale)
})();
*/

// manually chaning language
// i18n.changeLanguage(lng);
