import { Row, Col, Image } from 'antd';
import SectionDetail from '../../components/SectionDetail';
import industrialDesign from '../../assets/industrial_design.png';
import buiscuitMaker from './assets/biscuit_maker.png';
import others from './assets/others.png';
import { defaultGutter } from '../../lib/common/styles';
import Text from '../../components/Text';

function IndustrialDesignPage() {
  return (
    <SectionDetail title="As an Industrial Designer" imgSrc={industrialDesign}>
      <Row>
        <Text type="sub-title-1">Professional Experience</Text>
      </Row>
      <Row>
        <p>
          The industrial design engineering background allowed me to pay
          attention at the slightest details to make the products work in all
          the aspects; esthetically and practically utilizing various CAD
          software thus thoroughly using SolidWorks in machine design and
          mastering it the most. I got a great experience working as an
          industrial designer to produce brand concepts through sketches and CAD
          for consumer goods, housewares and furniture. I also conducted deep
          research and user testing to push new innovative design based on
          usability, interaction, and ergonomic principles
        </p>
      </Row>
      <Row>
        <Text type="sub-title-1">Main Projects</Text>
      </Row>
      <Row gutter={[defaultGutter, defaultGutter]}>
        <Col sm={0} md={3} />
        <Col sm={24} md={18}>
          <Image src={buiscuitMaker} />
        </Col>
        <Col sm={0} md={3} />
      </Row>
      <Row gutter={[defaultGutter, defaultGutter]}>
        <Col sm={0} md={3} />
        <Col sm={24} md={18}>
          <Image src={others} />
        </Col>
        <Col sm={0} md={3} />
      </Row>
    </SectionDetail>
  );
}

export default IndustrialDesignPage;
