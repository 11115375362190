import { css } from '@emotion/react';
import { COLORS } from '../../../../lib/common/styles';

export const cardStyle = css`
  background: ${COLORS.blackThree};
  :hover {
    cursor: default;
  }
`;

export const rightSectionStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
