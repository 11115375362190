import React from 'react';
import { Row } from 'antd';
import { pathnames } from '../../lib/routes/pathnames';
import Section from './components/Section';

import vd1 from './assets/previews/vis/1.jpg';
import vd2 from './assets/previews/vis/2.jpg';
import vd3 from './assets/previews/vis/3.jpg';

import pd1 from './assets/previews/prod/1.png';
import pd2 from './assets/previews/prod/2.png';

import art1 from './assets/previews/art/0.jpg';
import art2 from './assets/previews/art/1.jpg';
import art3 from './assets/previews/art/2.jpg';

import ux1 from './assets/previews/ux-ui/1.png';
import { centeredFlex } from '../../lib/common/styles';

import Text from '../../components/Text';

interface ISection {
  name: string;
  link: string;
  images: string[];
}

const portfolioSections: ISection[] = [
  {
    name: 'Visual Design',
    link: pathnames.visualDesign,
    images: [vd1, vd2, vd3],
  },
  {
    name: 'Product Design',
    link: pathnames.productDesign,
    images: [pd1, pd2],
  },
  {
    name: 'UX/UI Design',
    link: pathnames.uiUxDesign,
    images: [ux1],
  },
  {
    name: 'Fine Arts',
    link: pathnames.fineArts,
    images: [art1, art2, art3],
  },
];

function Portfolio() {
  return (
    <>
      <Row align="middle" justify="center">
        <Text type="main-title">My Portfolio</Text>
      </Row>
      {portfolioSections.map((section) => (
        <Section
          key={section.name}
          name={section.name}
          link={section.link}
          images={section.images}
        />
      ))}
    </>
  );
}

export default Portfolio;
