import { BackTop, Card } from 'antd';
import React from 'react';
import Platforms from './components/platforms/platforms';
import { footerStyle, SignatureDiv } from './style';

function Footer() {
  return (
    <Card css={footerStyle} bordered={false}>
      <BackTop />
      <Platforms />
      <SignatureDiv>
        Made with love in 2020 by
        <b> Ansem Ben Romdhane.</b>
      </SignatureDiv>
    </Card>
  );
}

export default Footer;
